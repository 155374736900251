import React from "react"
import PageTitle from "../../components/PageTitle"
import ContentBox from "../../components/Content-box"
import Layout from "../../components/Layout"

const Team = () => {
  return (
    <Layout title="Team">
      <PageTitle title="Team" />
      <ContentBox>
        <h3>Team Wijnandsrade-Nuth</h3>
        <div>
          <h4>Karin van Havere</h4>
          <ul>
            <li>Geriatrische fysiotherapie</li>
            <li>Huisbezoeken</li>
            <li>Valtraining</li>
            <li>Parkinson</li>
            <li>Begeleiding COPD</li>
            <li>Beweegkuur</li>
            <li>Running fysiotherapie</li>
          </ul>
        </div>
        <div>
          <h4>Yvonne Heij</h4>
          <ul>
            <li>NAP-therapie </li>
            <li>NDT</li>
            <li>COPD en astma</li>
            <li>COVID-19</li>
            <li>Claudicatio intermittens / etalagebenen</li>
            <li>Hartfalen</li>
            <li>Motivational interviewing</li>
            <li>Leefstijlcoaching</li>
            <li>Mimetherapie</li>
          </ul>
        </div>
        <div>
          <h4>Mark Souren</h4>
          <ul>
            <li>Reguliere fysiotherapie</li>
            <li>Mc Kenzie</li>
            <li>Mulligan ABCD</li>
            <li>Athlete Perfomance</li>
            <li>Tapen medical en Klassiek</li>
            <li>Artrose en artritis (IAS)</li>
            <li>Wevelkolom (IAS)</li>
            <li>Sportbegeleiding/fysiotherapie</li>
            <li>Individuele of groepscoaching</li>
            <li>Second opinion</li>
            <li>Huisbezoeken</li>
          </ul>
        </div>
      </ContentBox>
    </Layout>
  )
}

export default Team
